import React from 'react';
import '../../../App.css';
import './SectionD.css';
import SectionDCards from './SectionDCards'
import Footer from '../../Footer'

export default function SectionD() {
    return (
      <>
      <div class = 'cont'>
        <h1 className = 'section-d-title'>Part D: Operation Amplifier and application circuits.</h1>
      </div>
      <SectionDCards/>
      <Footer />
      </>
    );
  }
