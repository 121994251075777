import SectionB1Cards from './SectionB1Cards.js'
import React from 'react';
import '../../../../App.css'
import Footer from '../../../Footer'
import '../SectionB.css';

export default function SectionB1(){
    return(
        <>
        <div class = 'cont'>
            <h1 className = 'section-b1-title'>Experiment B1: step voltage transient response of capacitor</h1>
        </div>
        <SectionB1Cards/>
        <Footer />
        </>
    )
}
