import React, { Component} from 'react';
import CardItem from '../../../CardItem';
import '../../../Container.css';
import '../SectionA.css'
import axios from "axios";
import Form from 'react-bootstrap/Form';
import Cookie from 'js-cookie';
import {BackEndpath} from './../../APIpath';

class SectionA2Cards extends Component{

    constructor(props){
        super(props);
        this.state = {
            A2_1: "",
            mail_id : `${Cookie.get("mail_id")}`,
        }
    };

    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value });
      };

    submitAns = async e=>{
        e.preventDefault();
        axios
        .post(`${BackEndpath}/api/answers/submit`, {
            mail_id : this.state.mail_id,
            Section: ['A2_1'],
            Answer : [this.state.A2_1],
        }, {
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then((response) => {
            alert(JSON.stringify(response.data.message));
            window.location.reload();
        })
    }
    render(){
        const {A2_1} = this.state;
    return (
        <div className = 'cards'>
            <div className = 'cards__container'>
                <div className = 'cards__wrapper'>
                    <ul className='cards__items'>
                        <div className='cards__item__link'>
                            <li className='cards__item'>
                                <div className='cards__item__info'>
                                    <h5 className = 'cards__item__text__cont'>As shown in figure A2.1, the unknown resistor value to be measured is R<sub>x</sub> which is determined by measuring the voltage across R<sub>x</sub> (V<sub>BC</sub>). The current flowing through V<sub>1</sub> is the same as the current flowing through  R<sub>x</sub>. Hence V<sub>BC</sub>/V<sub>AB</sub> = R<sub>x</sub>/R<sub>1</sub>. Since V<sub>BC</sub> + V<sub>AB</sub> = 3.3V, the following formula (A2.2) can be used to determine the value of R<sub>x</sub> with the known value of R<sub>1</sub> and the measurement of V<sub>L</sub> (assuming V<sub>BC</sub> = V<sub>L</sub>) :</h5>
                                </div>
                            </li>
                        </div>
                    </ul>
                    <ul className='cards__items'>
                        <CardItem
                        class = 'cards__item__pic-wrap-1'
                        src='images/ExA2.png'
                        text='potential divider circuit'
                        label='Figure A2.1'
                        />
                        <CardItem
                        class = 'cards__item__pic-wrap-1'
                        src='images/figureA2.2.png'
                        text='potential divider formula'
                        label='A2.2'
                        />
                    </ul>
                    <ul className='cards__items'>
                        <div className='cards__item__link'>
                            <li className='cards__item'>
                                <div className='cards__item__info'>
                                    <h5 className = 'cards__item__text__cont'>Wire up figure A2.1 with R<sub>1</sub> = 10 kΩ and the given unknown resistor marked  R<sub>x</sub>.</h5>
                                    <br></br>
                                    <h5 className = 'cards__item__text__cont'>Measure V<sub>L</sub> and use equation (A2.2) to evaluate R<sub>x</sub>. Comment on the accuracy of the measurement and discuss how the accuracy can be improved in the space provided below:</h5>
                                    <br></br>
                                        <Form>
                                        <Form.Group controlId="AnswerA2_1">
                                            <Form.Control
                                            autoFocus
                                            as = "textarea"
                                            rows = {6}
                                            name = "A2_1"
                                            value = {A2_1}
                                            onChange = {this.changeHandler}
                                            />
                                        </Form.Group>
                                        <div class='righted'>
                                            <button className = 'button' onClick={this.submitAns}><span>Submit</span></button>
                                        </div>
                                        </Form>
                                   </div>
                                </li>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
    );
    }
}

export default SectionA2Cards
