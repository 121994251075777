import React from 'react'
import '../../App.css'
import './PageNotAuthorized.css';

function PageNotAuthorized() {
    return (
        <>
        <div className = 'pbody'>
            <h1 className = 'user'>User Is Not Authorized !</h1>
            <p class = 'subtitle'>Please Login To Your Account!</p>
        </div>
        </>
    )
}

export default PageNotAuthorized
