import React, { Component} from 'react';
import Container from '../../../Container';
import CardItem from '../../../CardItem';
import '../../../Container.css';
import '../SectionB.css'
import Frame from 'react-frame-component';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import {BackEndpath} from './../../APIpath';
import Cookie from 'js-cookie';

class SectionB2Cards extends Component{

    constructor(props){
        super(props);
        this.state = {
            B2_1 : "",
            mail_id : `${Cookie.get("mail_id")}`,
        }
    };

    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value });
      };

    submitAns = async e=>{
        e.preventDefault();
        axios
        .post(`${BackEndpath}/api/answers/submit`, {
            mail_id : this.state.mail_id,
            Section: ['B2_1'],
            Answer : [this.state.B2_1],
        }, {
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then((response) => {
            alert(JSON.stringify(response.data.message));
            window.location.reload();
        })
    }

    render(){
    return(
        <>
        <div className = 'cards'>
            <div className = 'cards_container'>
                <div className = 'cards_wrapper'>
                    <ul className='cards__items'>
                        <Container
                        text='In part B1, we generate a step voltage input source using a mechanical switch. All mechanical switches suffer from bounce effect (ie on/off connections over a short period of time in the order of 1mS before stabilizing due to the bouncing of physical contact).  If the measured time constant of the circuit concerned is less than 1 mS, this bounce effect will affect the measurement. An electronic switch may be used to overcome this. In this experiment, we are going to generate a periodic switching source (ie a square wave voltage source) to the circuit. This is slightly different from the example in part B1 in that when the mechanical switch is in the off position, there is high resistance from the voltage source to the circuit. However, in this switching source, the voltage source is always connected to the circuit with voltage changing from 0V (off) to 3.3V (on). The on off duration can be equal (for square-wave) or some other duty cycle as the case for pulse repetitive signal. As the voltage source is generated through a GPIO pin, there is a limit to the source/sink current of 25mA for the STM32F407 uC. '
                        />
                    </ul>
                    <ul className='cards__items'>
                        <Container
                        text = 'Micropython codes for generating square-wave using GPIO output and two channel ADC timed measurement'
                        />
                    </ul>
                    <ul className='cards_items'>
                        <CardItem
                        class='cards_item_B2Code'
                        src='./B2Code.png'
                        text='Code'
                        label = ''
                        />
                    </ul>
                    <ul className='cards_items'>
                        <CardItem
                        class='cards_item_B2CD'
                        src='./B2CD.png'
                        text='Circuit diagram'
                        label = 'Figure B2.1: inductor circuit transient response'
                        />
                    </ul>
                    <ul className='cards_items'>
                        <CardItem
                        class='cards_item_B2WD'
                        src='./B2WD.png'
                        text='Wired-up photo'
                        label = 'Figure B2.2: photo of setup for inductor transient response measurement'
                        />
                    </ul>
                    <ul className='cards__items'>
                        <Container
                        text = 'Wire up the circuit in figure B2.1 and measure how inductor voltage Vl vary with time. The switching voltage source output is at pin ‘PD1’. It should be connected to the Trigger pin ‘PD0’ and to ADC pin ‘PA0’ as shown in figure B2.2. The second ADC at pin ‘PA1’ should be connected to measure the inductor voltage Vl. The printed ADC readings with time can be observed in the COM port terminal. '
                        />
                    </ul>
                    <ul className='cards__items'>
                        <Container
                        text = 'Determine the inductor voltage (Vl) at t=0 (time point when the voltage source switch to 3.3V). Determine the steady state inductor voltage (t=infinity, time point when the switch is on for a long time such that the voltages has reached steady state). Compare this with the calculated value and discuss possible causes for the difference (Clue: lookup the DC resistance and self-resonance frequency of the inductor in Appendix B2. The self-resonance is due to parasitic capacitance in between the windings of the coil which also have losses). Determine the values of wire resistance Rind in series with the inductor, parasitic capacitance Cind in parallel with the inductor, and inductor core losses Rcap in series with Cind. '
                        />
                    </ul>
                    <ul className='cards__items'>
                        <Container
                        text = 'Determine the time (tf) taken for Vl to reach 63% of its changing value.  Compare this with the calculated value and discuss possible causes for the difference.'
                        />
                    </ul>
                    <ul className='cards__items'>
                        <Container
                        text = 'Python codes for visualizing the measurements'
                        />
                    </ul>
                    <ul className='cards__items'>
                        <Container
                        text = 'The printed ADC readings in the above experiment is best visualized as a voltage versus time graph in the screen of a Personal Computer (PC). For graph plotting on PC, Python module “Plotly” can be used. To read the printed ADC readings from the COM port, Python module “Serial” can be used. The codes assuming that your PC is setup with Jupyter Notebook (web application for interactive Python programming) and necessary library modules.'
                        />
                    </ul>
                    <ul className='cards_items'>
                        <CardItem
                        class='cards_item_B2Code2'
                        src='./B2Code2.png'
                        text='Code'
                        label = 'Figure B2.3'
                        />
                    </ul>
                    <ul className='cards_items'>
                        <CardItem
                        class='cards_item_B2Graph'
                        src='./B2Graph.png'
                        text='Graph'
                        label = 'Figure B2.4'
                        />
                    </ul>
                    <ul className='cards__items'>
                        <Container
                        text = 'The left Y axis is measured voltage in Volts for Vs, right Y axis is measured voltage in Volts for Vind and the X axis is time. As the ADC sampling rate is set at 200 kHz, the scale is in 5 uS per point.'
                        />
                    </ul>
                </div>
            </div>
        </div>
        </>
    )
    };
}
export default SectionB2Cards
