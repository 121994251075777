import React from 'react';
import Container from '../../../Container.js';
import CardItem from '../../../CardItem.js';
import '../../../Container.css';
import Frame from 'react-frame-component';
import '../SectionC.css'

function SectionC1Cards() {
    return (
        <div className = 'cards'>
            <div className = 'cards__container'>
                <div className = 'cards__wrapper'>
                    <ul className='cards__items'>
                        <Container
                        text='Connect up the potentiometer circuit shown in figure A1.1. With : Vs = 3.3 V ,  R1 + R2 = 10 k Ω and RL (input resistance of ADC) > 500 k Ω. Picture showing wired up VL monitored in pin ‘PA0’ and Vs monitored in pin ‘PA1’'
                        />
                    </ul>
                    <ul className='cards__items'>
                        <CardItem
                        class = 'cards__item__pic-wrap-1'
                        src='images/figureA1.1.png'
                        text='potentiometer circuit diagram'
                        label='Figure A1.1'
                        />
                        <CardItem
                        class = 'cards__item__pic-wrap-1'
                        src='images/figureA1.2.png'
                        text='potentiometer circuit photo '
                        label='Figure A1.2'
                        />
                    </ul>
                    <ul className='cards__items'>
                        <Container
                        text='Micropython codes for the 2 channel voltmeter'
                        />
                    </ul>
                    <div class='icontainer'>
                    <Frame className = 'responsive-iframe'initialContent="<!DOCTYPE html><html><head></head><body><script src='https://gist.github.com/Deonatan/33ab6858bb034e6bb98171ea6b6aa883.js'></script></body></html>" />
                    </div>
                    <br></br>
                    <ul className='cards__items'>
                        <Container
                        text='Install PuTTY and open a serial COM port connection to the STM32F4VE board to monitor the printout of voltmeters reading as shown in the figure below.'
                        />
                    </ul>
                    <ul className='cards__items'>
                        <CardItem
                        class = 'cards__item__pic-wrap-1'
                        src='images/figureA1.3.png'
                        text='Connections with PuTTY terminal'
                        label='Figure A1.3'
                        />
                        <CardItem
                        class = 'cards__item__pic-wrap-1'
                        src='images/figureA1.4.png'
                        text='Sample Putty Output'
                        label='Figure A1.4'
                        />
                    </ul>
                    <ul className='cards__items'>
                        <Container
                        text='Verify the voltage output of the potentiometer at v1 can be varied from 0 V to 3.29 V. '
                        />
                    </ul>
                    <ul className='cards__items'>
                        <Container
                        text='Next set the potentiometer to have VL = 1.5 V. Connect a load resistor RL with values varying from 100 Ω to 10 k Ω and measure the load voltage VL with the intention to extract the Thevenin’s equivalent circuit for the potentiometer set at Vth = 1.5 V.  Use the extracted Thevenin’s equivalent circuit to calculate the load voltage VL for RL= 200 Ω and 1 k Ω Measure the load voltage VL and compare against the calculated.'
                        />
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default SectionC1Cards
