import React from 'react';
import '../../../../App.css';
import '../SectionA.css';
import SectionA3Cards from './SectionA3Cards';
import Cookie from 'js-cookie';

export default function SectionA1() {

    Cookie.set('rndmSet1', Math.floor(Math.random()* 348))
    Cookie.set('rndmSet2', Math.floor(Math.random()* 348))
    Cookie.set('rndmSet3', Math.floor(Math.random()* 348))

    return(
        <>
        <div class = 'cont'>
            <h1 className = 'section-a-title'>Experiment A3: Determine current flow in a multi-tier potential divider circuit </h1>
        </div>
        <SectionA3Cards/>
        </>
    )
}
