import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./components/pages/msalAuth/authConfig";

const msalInstance = new PublicClientApplication(msalConfig);


ReactDOM.render(
    <React.StrictMode>
        <MsalProvider instance={msalInstance}>
           <App />
        </MsalProvider>
    </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();


