import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className='footer-container'>
      {/* <div class='footer-links'>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>Contact Us</h2>
            <Link to='/'>Email : ​eeestudents@ntu.edu.sg​​</Link>
            <Link to='/'>Phone Number : (65) 6790 5367</Link>
          </div>
        </div>
      </div> */}
      <section class='social-media'>
        <div class='social-media-wrap'>
          <div class='footer-logo'>
            <Link to='/' className='social-logo'>
              EEE
              <i className = 'fas fa-wave-square' />
            </Link>
          </div>
          <small class='website-rights'>EEE © 2021</small>
          {/* <div class='social-icons'>
          </div> */}
        </div>
      </section>
    </div>
  );
}

export default Footer;