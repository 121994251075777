import React from 'react';
import '../../../../App.css';
import '../SectionD.css';
import SectionD1Cards from './SectionD1Cards';
import Footer from '../../../Footer'

export default function SectionD1() {
    return(
        <>
        <div class = 'cont'>
            <h1 className = 'section-d-title'>Experiment A1: potential divider circuit using a potentiometer</h1>
        </div>
        <SectionD1Cards/>
        <Footer />
        </>
    )
}
