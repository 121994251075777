import React from 'react';
import '../../../../App.css';
import '../SectionF.css';
import SectionF2Cards from './SectionF2Cards';
import Footer from '../../../Footer'

export default function SectionF2() {
    return(
        <>
        <div class = 'cont'>
            <h1 className = 'section-a-title'>Experiment A1: potential divider circuit using a potentiometer</h1>
        </div>
        <SectionF2Cards/>
        <Footer />
        </>
    )
}
