import React from 'react';
import '../App.css'
import './Container.css'
import './Container.js'

export default function Instruction() {
    return(
        <>
            <h1>INSTRUCTION</h1>
            <div className = 'instruction'>
                <p>content</p>
                <a href='#Source'>1. Getting the source code</a>
                <br/>
                <a  id = 'Source' href='#Structure'>2. Web Structure</a>
                <br />
                <a href = '#Server'>3. Connecting to the server</a>
            </div>
            <div className = 'cards'>
                <div className = 'cards__container'>
                    <div className = 'cards__wrapper'>
                        <li className='cards__item'>
                            <div className='cards__item__link'>
                                <div className='cards__item__info'>
                                    <h2>Getting The Source Code</h2>
                                    <br/>
                                    <hr/>
                                    <p id = 'Structure'>1. Please Provide your github username / email. (you can email it to prof or me, my email: deon0009@e.ntu.edu.sg). Then wait until I add you as contributor in the github repository.</p>
                                    <hr/>
                                    <p>2. Clone the repository and you can push any update to the repository. Make sure you don't change the code directly on the github page.</p>
                                </div>
                            </div>
                        </li>
                        <li className='cards__item'>
                            <div className='cards__item__link'>
                                <div className='cards__item__info'>
                                    <h2 >Basic Web Structure </h2>
                                    <br/>
                                    <hr/>
                                    <div class = 'row'>
                                        <div class='column'>
                                            <p>1. The static files is located in the src folder. photos and videos are located in the public folder.</p>
                                        </div>
                                        <div class='column'>
                                            <img src = {'images/instructionImages/organization.png'} alt='eee'/>
                                        </div>
                                    </div>
                                    <br></br>
                                    <hr></hr>
                                    <div class = 'row'>
                                        <div class='column'>
                                            <p>2. Create another section file in 'src/components/pages/ExperimentsList' (create e.g : SectionB.js) create also the css part in this folder. Then Create another subsection of your corresponding section. This subsection should content the futher experiment part (e.g part A.1 , A.2 , A.3).</p>
                                        </div>
                                        <div class='column'>
                                            <img src = {'images/instructionImages/subsection.png'} alt='subsect'></img>
                                        </div>
                                    </div>
                                    <br></br>
                                    <hr></hr>
                                    <div class = 'row'>
                                        <div class='column'>
                                            <p>3. Utilize the reusable functions in the main components to use different feature e.g (button, CardItem, Container, footer, Navbar, Container, etc). Brief functions description: container is a box for words , CardItem is a function to put image and a description(useful to make subsection). Example to use function: </p>
                                            <img src = {'images/instructionImages/function(1).png'} alt='f1'></img>
                                        </div>
                                        <div class='column'>
                                            <img class = 'centered' src = {'images/instructionImages/components.png'} alt='comp'></img>
                                        </div>
                                    </div>
                                    <br></br>
                                    <hr></hr>
                                    <div class = 'row'>
                                        <div class='column'>
                                            <p>4. Each time there is a new branch, make sure to create a new route in the app.js</p>
                                            <img id = 'Server' src={'images/instructionImages/route.png'} alt='route'></img>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className='cards__item'>
                            <div className='cards__item__link'>
                                <div className='cards__item__info'>
                                    <h2>Connecting to Server</h2>
                                    <hr></hr>
                                    <p>1. Download PUTTY <a href ="https://www.putty.org/">In this link</a>.</p>
                                    <hr></hr>
                                    <p>2. Set Up the Putty Configuration as shown in the image. After setting up you can save the configuration. </p>
                                    <img src = {'images/instructionImages/puttyconfig.png'} alt='Putty'/>
                                    <br/>
                                    <hr/>
                                    <p>3. Login to your account in the digital ocean server</p>
                                    <img src = {'images/instructionImages/puttylogin.png'} alt='PuttyLogin'></img>
                                    <hr/>
                                    <p>4. Push any updates to the server by using github also. (you have another alternative to use filezilla to send files).</p>
                                </div>
                            </div>
                        </li>
                    </div>
                </div>
            </div>
        </>
    )
}