import React from 'react';
import Container from '../../Container.js';
import CardItem from '../../CardItem.js';
import '../../Container.css';

function SectionACards() {
    return (
        <div className = 'cards'>
            <div className = 'cards__container'>
                <div className = 'cards__wrapper'>
                    <ul className='cards__items'>
                        <Container
                        text='As shown in figure A1, the multi-function learning kit is based on the STM32F407VET uC (microcontroller) development board flashed with firmware running MicroPython.'
                        />
                    </ul>
                    <ul className='cards__items'>
                        <CardItem
                        class = 'cards__item__pic-wrap-1'
                        src='images/figureA1.png'
                        text='Multi-function Learning Kit'
                        label='Figure A1'
                        />
                    </ul>
                    <ul className='cards__items'>
                        <Container
                        text='To get started, connect the miniUSB cable to a PC. The development board will power up and enumerate as a drive “BLACKF407VE” with files as shown in Figure A2.'
                        />
                    </ul>
                    <ul className='cards__items' >
                        <CardItem
                        class = 'cards__item__pic-wrap-2'
                        src='images/figureA2.png'
                        text='Development board drive label in windows PC and files '
                        label='Figure A2'
                        />
                    </ul>
                    <ul className='cards__items'>
                        <Container
                        text='The Python file “boot.py” instruct the correct bootup of the development board and should not be changed. The Python file “main.py” is the firmware that setups the hardware resources taking inputs and provide outputs through the UART (over USB) port.  The UART (COM) port number can be found by opening the Device Manager in the Windows PC. The example shown in Figure A3 shows USB Serial Device (COM27) as the UART port number for the development board.'
                        />
                    </ul>
                    <ul className='cards__items' >
                        <CardItem
                        class = 'cards__item__pic-wrap-3'
                        src='images/figureA3.png'
                        text='Development board UART port number in Windows PC'
                        label='Figure A3'

                        />
                    </ul>
                    <ul>
                        <li className='cards__item'>
                            <div className='cards__item__link'>
                                <div className='cards__item__info'>
                                    <h2>Writing firmware codes for the STM32F407VET6 board to function as a DC voltmeter</h2>
                                    <br></br>
                                    <h5 className='cards__item__text__cont'>First, we are going to write firmware codes (using Python) for the STM32F407VET6 board to function as a voltmeter. To measure voltage, we are going to use the ADC (Analog to Digital Converter) in the uC. There are three ADC channels in the STM32F407VET6 uC which can be used to measure up to 16 input in time multiplexed manner.</h5>
                                    <br></br>
                                    <h5 className='cards__item__text__cont'>The 16 inputs (+ input terminal of the voltmeter) are may be applied at any of the following labeled GPIO Pins:</h5>
                                    <h5 className='cards__item__text__cont'>
                                        <ul className = 'points'>
                                            <li>PA0 to PA7 (inputs 0 to 7)</li>
                                            <li>PB0 and PB1 (inputs 8, 9)</li>
                                            <li>PC0 to PC5 (inputs 10 to 15)</li>
                                        </ul>
                                    </h5>
                                    <br></br>
                                    <h5 className ='cards__item__text__cont'>❗ Do bear in mind that the return (negative) wire for the voltmeter is labeled as GND and that is common for all 16 inputs. Also note that the maximum voltage measured at the input pin must not exceed +3.3V otherwise physical damage will occur to that input.</h5>
                                    <br></br>
                                    <h5 className = 'cards__item__text__cont'>An external potential divider with known divider ratio is required for measurement above this voltage. It is also good practice to have a 3.3V Zener diode at each input pin to protect the input from damage due to accidental connection of voltage higher than +3.3V. Likewise, another diode can be used to protect the input from negative voltage input exceeding -0.7V from damaging the input.</h5>
                                    <br></br>
                                    <h5 className = 'cards__item__text__cont'>For the codes, the important library to interact with hardware is the <a href='https://docs.micropython.org/en/v1.13/library/pyb.html'>“pyb” module (code line: “import pyb”)</a>. There are many functions and classes in this module. To use an ADC on a particular channel, we need to create the ADC object  (code line: “vm1 = pyb.ADC(‘PA0’)”) named vm1 with GPIO labeled “PA0” as the input pin. Note that to shorten pyb.ADC as ADC, you can use “from pyb import ADC”.</h5>
                                    <br></br>
                                    <h5 className = 'cards__item__text__cont'>For more information on ADC class, see <a href = 'https://docs.micropython.org/en/v1.13/library/pyb.ADC.html'>link</a>. To read the voltage applied at pin PA0 using ADC channel 1, use “v = vm1.read()”. The returned value of “v” is a 12-bit (ADC has 12 bit resolution) number = [0, 4095] with 4095 being +3.3 V.</h5>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <br></br>
                    <h1>Continue To The Next Experiment</h1>
                    <ul className='cards__items' >
                        <CardItem
                        class = 'cards__item__pic-wrap-1'
                        src='images/ExA1.png'
                        text='Potential divider circuit using a potentiometer'
                        label='Experiment A1'
                        path = '/section-a-1'
                        />
                        <CardItem
                        class = 'cards__item__pic-wrap-1'
                        src='images/ExA2.png'
                        text='Potential divider circuit for determining value of a resistor'
                        label='Experiment A2'
                        path = '/section-a-2'
                        />
                        <CardItem
                        class = 'cards__item__pic-wrap-1'
                        src='images/ExA3.png'
                        text='Determine current flow in multi-tier potential divider circuit'
                        label='Experiment A3'
                        path = 'section-a-3'
                        />
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default SectionACards
