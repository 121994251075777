import React, {useEffect , useState} from 'react';
import '../../App.css';
import axios from 'axios';
import Cookie from 'js-cookie'
import PageNotAuthorized from './PageNotAuthorized';
import './Grades.css'
import { useIsAuthenticated } from '@azure/msal-react';
import { BackEndpath } from './APIpath';

const handlePrivate = async e => {
  const headers = {
      authorization: `Bearer ${Cookie.get("jwt")}`
  }
  const response = await axios.get(`${BackEndpath}/api/user/private` , { headers });

  if (response.data.message){
      return true;
  }else{
      return false;
  }
  };

const scoreArray = [
    {section : "Section A1.1",
     score: "100",
     attemp: "5",
    },
    {section : "Section A1.2",
     score: "100",
     attemp: "5",
    },
    {section : "Section A1.3",
     score: "100",
     attemp: "5",
    },
]

export default function Experiments() {

  const [access , setAccess] = useState(false)
  const isAuthenticated = useIsAuthenticated()

  useEffect(() => {
    handlePrivate()
    .then((token) => {console.log(token); setAccess(token)})
  })

  return(
  <>
  {access === true || isAuthenticated? <>
  <h1 className='experiments'>Grades</h1>
  <div class="table-wrapper fullpage">
    <table class="fl-table">
        <thead>
        <tr>
            <th>Section</th>
            <th>Score</th>
            <th>Attemp</th>
        </tr>
        </thead>
        <tbody>
        {scoreArray.map(item=>(
            <tr>
                <td>{item.section}</td>
                <td>{item.score}</td>
                <td>{item.attemp}</td>
            </tr>
        ))}
        </tbody>
    </table>
</div>
  </> :
  <>
  <PageNotAuthorized />
  </>}
  </>
  )
}
