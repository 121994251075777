import React from 'react';
import '../../../App.css';
import './SectionF.css';
import SectionFCards from './SectionFCards'
import Footer from '../../Footer'

export default function SectionF() {
    return (
      <>
      <div class = 'cont'>
        <h1 className = 'section-f-title'>Part F: MOSFET transistor characteristic and application circuits.</h1>
      </div>
      <SectionFCards/>
      <Footer />
      </>
    );
  }
