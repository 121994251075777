import React from 'react';
import '../../../App.css';
import './SectionA.css';
import SectionACards from './SectionACards'

export default function SectionA() {
    return (
      <>
      <div class = 'cont'>
        <h1 className = 'section-a-title'>Part A: Introduction to Multi-function Learning Kit and measurement of DC voltage, resistance and current.</h1>
      </div>
      <SectionACards/>
      </>
    );
  }
