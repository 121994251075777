import React, {useState} from 'react';
import Container from '../../../Container';
import CardItem from '../../../CardItem';
import '../../../Container.css';
import '../SectionA.css'
import axios from "axios";
import Cookie from 'js-cookie';
import Form from 'react-bootstrap/Form';
import {BackEndpath} from './../../APIpath';
import data from './Resistor Value.json';

function SectionA3Cards() {

    const [A3_1 , setA3_1] = useState("");
    const [A3_2 , setA3_2] = useState("");
    const [A3_3 , setA3_3] = useState("");
    const [A3_4 , setA3_4] = useState("");

    const submitAns = async e=> {
        e.preventDefault();
        axios
        .post(`${BackEndpath}/api/answers/submit`, {
            mail_id : `${Cookie.get("mail_id")}`,
            Section: ['A3_1','A3_2','A3_3','A3_4'],
            Answer: [A3_1 , A3_2, A3_3, A3_4],
        }, {
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then((response) => {
            alert(JSON.stringify(response.data.message));
            window.location.reload();
        })
    };

    return (
        <div className = 'cards'>
            <div className = 'cards__container'>
                <div className = 'cards__wrapper'>
                    <ul className='cards__items'>
                        <Container
                        text='Wire up the circuit shown in figure A3.1 below and determine the current flowing through the 300 Ω, 200 Ω and 100 Ω resistors.'
                        />
                    </ul>
                    <ul className='cards__items'>
                        <CardItem
                        class = 'cards__item__pic-wrap-3'
                        src='images/figureA3.1.png'
                        text='Multi-tier potential divider circuit'
                        label='Figure A3.1'
                        />
                    </ul>
                    <ul className='cards__items'>
                        <div className='cards__item__link'>
                            <li className='cards__item'>
                                <div className='cards__item__info'>
                                    <h5 className = 'cards__item__text__cont'>Given resistance values as below: </h5>
                                    <br></br>
                                    <h5 className='cards__item__text__cont'>
                                        {data.map(item => (
                                            <ul>
                                                <li>R<sub>1</sub> = {item[`${Cookie.get('rndmSet1')}`]} </li>
                                                <li>R<sub>2</sub> = {item[`${Cookie.get('rndmSet2')}`]} </li>
                                                <li>R<sub>3</sub> = {item[`${Cookie.get('rndmSet3')}`]} </li>
                                            </ul>
                                        ))}
                                    </h5>
                                </div>
                            </li>
                        </div>
                        <div className='cards__item__link'>
                            <li className='cards__item'>
                                <div className='cards__item__info'>
                                    <h5 className = 'cards__item__text__cont'>Input the answer below:</h5>
                                    <br></br>
                                    <h5 className='cards__item__text__cont'>
                                    <Form>
                                        <Form.Group controlId="AnswerA3_1">
                                        <p>Current through resistor 300Ω :</p>
                                            <Form.Control
                                            autoFocus
                                            type = "text"
                                            size = "sm"
                                            name = "A3_1"
                                            value = {A3_1}
                                            onChange = {e => setA3_1(e.target.value)}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="AnswerA3_1">
                                        <p>Current through resistor 200Ω :</p>
                                            <Form.Control
                                            autoFocus
                                            type = "text"
                                            size = "sm"
                                            name = "A3_2"
                                            value = {A3_2}
                                            onChange = {e => setA3_2(e.target.value)}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="AnswerA3_1">
                                        <p>Current through resistor 100Ω :</p>
                                            <Form.Control
                                            autoFocus
                                            type = "text"
                                            size = "sm"
                                            name = "A3_3"
                                            value = {A3_3}
                                            onChange = {e => setA3_3(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Form>
                                    </h5>
                                </div>
                            </li>
                        </div>
                    </ul>
                    <ul className = 'cards__items'>
                        <div className='cards__item__link'>
                            <li className='cards__item'>
                                <div className='cards__item__info'>
                                    <h5 className='cards__item__text__cont'>Compare the measured current versus calculated using Thevenin’s equivalent circuit. Discuss what are the causes of any difference in the space provided below:</h5>
                                    <h5 className='cards__item__text__cont'>
                                    <br></br>
                                    <Form>
                                        <Form.Group controlId="AnswerA2_1">
                                            <Form.Control
                                            autoFocus
                                            as = "textarea"
                                            rows = {6}
                                            name = "A3_4"
                                            value = {A3_4}
                                            onChange = {e => setA3_4(e.target.value)}
                                            />
                                        </Form.Group>
                                        <div class='righted'>
                                            <button className = 'button' onClick={submitAns}><span>Submit</span></button>
                                        </div>
                                        </Form>
                                    </h5>
                                </div>
                            </li>
                        </div>
                    </ul>
                </div>
            </div>
        </div>
    )}

export default SectionA3Cards
