import React from 'react';
import '../../../../App.css';
import '../SectionE.css';
import SectionE1Cards from './SectionE1Cards';
import Footer from '../../../Footer'

export default function SectionE1() {
    return(
        <>
        <div class = 'cont'>
            <h1 className = 'section-a-title'>Experiment A1: potential divider circuit using a potentiometer</h1>
        </div>
        <SectionE1Cards/>
        <Footer />
        </>
    )
}
