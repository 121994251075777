import React from 'react';
import Container from '../../Container.js';
import CardItem from '../../CardItem.js';
import '../../Container.css';

function SectionBCards() {
    return (
        <div className = 'cards'>
            <div className = 'cards__container'>
                <div className = 'cards__wrapper'>
                    <ul className='cards__items'>
                        <Container
                        text='In part A, we are dealing with DC signals (voltage and current) which do not vary with time. In many applications, we need to apply transient signals (ie toggle a switch which connect a DC voltage source to the circuit).'
                        />
                    </ul>
                    <ul className='cards__items'>
                        <CardItem
                        class = 'cards__item__pic-wrap-1'
                        src='images/figureB.png'
                        text='MicroPython codes for transient voltage measurement'
                        label='Figure B'
                        />
                    </ul>
                    <ul className='cards__items'>
                        <Container
                        text='The above sample code uses pin ‘PD0’ as trigger input (named as trg). Once ‘PD0’ goes high, the ADC object “vm1” is used in read_timed mode with ADC sampling frequency (fsamp) = 100 Hz, number of points (npts) = 100. After capturing the signal, it prints out the captured data points and waits for 5 seconds before running the measurement again.'
                        />
                    </ul>
                    <br></br>
                    <h1>Continue To The Next Experiment</h1>
                    <ul className='cards__items' >
                        <CardItem
                        class = 'cards__item__pic-wrap-1'
                        src='images/ExB1.png'
                        text='step voltage transient response of capacitor'
                        label='Experiment B1'
                        path = '/section-b-1'
                        />
                        <CardItem
                        class = 'cards__item__pic-wrap-1'
                        src='images/ExB2.jpg'
                        text='step voltage transient response of inductor'
                        label='Experiment B2'
                        path = '/section-b-2'
                        />
                        <CardItem
                        class = 'cards__item__pic-wrap-1'
                        src='images/ExB3.jpg'
                        text='laplace tranform'
                        label='Experiment B3'
                        path = '/section-b-3'
                        />
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default SectionBCards
