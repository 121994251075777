import React, { Component} from 'react';
import Container from '../../../Container';
import CardItem from '../../../CardItem';
import '../../../Container.css';
import '../SectionB.css'
import Frame from 'react-frame-component';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import {BackEndpath} from './../../APIpath';
import Cookie from 'js-cookie';

class SectionB1Cards extends Component{

    constructor(props){
        super(props);
        this.state = {
            B1_1 : "",
            mail_id : `${Cookie.get("mail_id")}`,
        }
    };

    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value });
      };

    submitAns = async e=>{
        e.preventDefault();
        axios
        .post(`${BackEndpath}/api/answers/submit`, {
            mail_id : this.state.mail_id,
            Section: ['B1_1'],
            Answer : [this.state.B1_1],
        }, {
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then((response) => {
            alert(JSON.stringify(response.data.message));
            window.location.reload();
        })
    }

    render(){
    return (
        <div className = 'cards'>
            <div className = 'cards_container'>
                <div className = 'cards_wrapper'>
                    <ul className='cards_items'>
                        <CardItem
                        class='cards_item_B1CD'
                        src='./B1CD.png'
                        text='Circuit diagram'
                        label='R1 = 2000Ω'
                        ></CardItem>
                    </ul>
                    <ul className='cards_items'>
                        <CardItem
                        class='cards_item_B1WD'
                        src='./B1WD.png'
                        text='Circuit wired up diagram'
                        label='Figure B1 -  capacitor circuit and wired-up photo -    Picture of how it is wired up with Vtrigger connected to pin ‘PD0’ and Vc measured in pin ‘PA0’'
                        ></CardItem>
                    </ul>
                    <ul className='cards__items'>
                        <Container
                        text='The above figure B1 photo shows the wired-up circuit with the red wire used as a switch. When connected to the 3.3 V supply line, the ADC will be triggered to measure Vc variation with time. '
                        />
                    </ul>
                    <ul className='cards_items'>
                        <CardItem
                        class='cards_item_B1Output'
                        src='./B1Output.png'
                        text='Output Diagram'
                        label='Figure B1.3'
                        ></CardItem>
                    </ul>
                    <ul className='cards__items'>
                        <Container
                        text='The printed output at the COM port is as shown above with R1 = 2000 . As the ADC sampling frequency is 100 Hz, each line is 10 mS. The time taken for Vc to reach 63% of its final value (2.08 V) is around 100mS.'
                        />
                    </ul>
                    <ul className='cards__items'>
                        <Container
                        text='Next change the value of resistor R1 = [1000, 1100, 1200, 1300, 1500, 1600, 1800, 2000, 2200, 2400, 2700, 3000, 3300, 3600, 3900, 4300, 4700, 5100, 5600, 6200, 6800, 7500, 8200, 9000, 9100, 10000, 11000, 12000, 15000, 16000, 20000] and measure Vc versus time.'
                        />
                    </ul>
                    <ul className='cards__items'>
                        <Container
                        text='Determine the time (tr) taken for Vc to reach 63% of its final value.  Compare this with the calculated value and discuss possible causes for the difference.'
                        />
                    </ul>
                </div>
            </div>
        </div>
    )
    };
}
export default SectionB1Cards
