import React from 'react';
import { Link } from 'react-router-dom';
import './Cards.css';

function CardItem(props) {

  return (
    <>
      <li className='cards__item'>
        <Link className='cards__item__link' to={props.path}>
          <figure className={props.class} data-category={props.label}>
            <img
              className='cards__item__img'
              alt='Images'
              src={props.src}
            />
          </figure>
          <div className='cards__item__info'>
            <a href = {props.link}>
            <h5 className='cards__item__text'>{props.text}</h5>
            </a>
          </div>
        </Link>
      </li>
    </>
  );
}

export default CardItem;
