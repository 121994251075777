import React from 'react';
import '../../../App.css';
import './SectionB.css';
import SectionBCards from './SectionBCards'
import Footer from '../../Footer'

export default function SectionB() {
    return (
      <>
      <div class = 'cont'>
        <h1 className = 'section-b-title'>Part B: Measurement of transient voltage with resistor, capacitor and inductor circuit.</h1>
      </div>
      <SectionBCards/>
      <Footer />
      </>
    );
  }
