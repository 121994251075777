import React from 'react';
import CardItem from '../CardItem';
import '../Cards.css';

function ExperimentsCards() {
    return (
        <div className = 'cards'>
            <div className = 'cards__container'>
                <div className = 'cards__wrapper'>
                    <ul className='cards__items'>
                        <CardItem
                        class = 'cards__item__pic-wrap-1'
                        src='images/section A.jpg'
                        text='Introduction to Multi-function Learning Kit and measurement of DC voltage, resistance and current'
                        label='Section A'
                        path='/section-a'
                        />
                        <CardItem
                        class = 'cards__item__pic-wrap-1'
                        src='images/section B.png'
                        text='Measurement of transient voltage with resistor, capacitor and inductor circuit'
                        label='Section B'
                        path='/section-b'
                        />
                        <CardItem
                        class = 'cards__item__pic-wrap-1'
                        src='images/section C.png'
                        text='Measurement of square-wave voltage source with resistor, capacitor and inductor circuit'
                        label='Section C'
                        path='/section-c'
                        />
                    </ul>
                    <ul className='cards__items'>
                        <CardItem
                        class = 'cards__item__pic-wrap-1'
                        src='images/section D.png'
                        text='Operation Amplifier and application circuits'
                        label='Section D'
                        path='/section-d'
                        />
                        <CardItem
                        class = 'cards__item__pic-wrap-1'
                        src='images/section E.png'
                        text='Diode IV characteristic and application circuits'
                        label='Section E'
                        path='/section-e'
                        />
                        <CardItem
                        class = 'cards__item__pic-wrap-1'
                        src='images/section F.png'
                        text='BJT transistor characteristic and application circuits'
                        label='Section F'
                        path='/section-f'
                        />
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default ExperimentsCards
