import React, { Component } from "react";
import {Link} from 'react-router-dom';
import axios from "axios";
import Form from "react-bootstrap/Form";
import { BackEndpath } from "./APIpath";
import "../../App.css";
import "./Register.css";

class Register extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      email: "",
      password: "",
    };
  }

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  submitHandler = (e) => {
    axios
      .post(`${BackEndpath}/api/user/register`, {
        name :this.state.name,
        email: this.state.email,
        password: this.state.password
      }, {
        headers: {
            "Content-Type": "application/json"
        }
    })
      .then((response) => {
        console.log(response);
        alert(JSON.stringify(response.data.message));
      })
      .catch((error) => {
        console.log({...error.response});
        alert({...error.response});
      });
  };

  render() {
    const { name, email, password } = this.state;
    return (
      <div className = 'Register'>
        <div className = 'fullpage'>
        <Form>
          <h1>Register</h1>
          <br></br>
          <Form.Group size="lg" controlId="name">
            <Form.Label>Username: </Form.Label>
              <Form.Control
                  autoFocus
                  type="text"
                  name="name"
                  value={name}
                  onChange={this.changeHandler}
              />
          </Form.Group>
          <Form.Group size="lg" controlId="email">
            <Form.Label>Email: </Form.Label>
              <Form.Control
                  autoFocus
                  type="email"
                  name="email"
                  value={email}
                  onChange={this.changeHandler}
              />
          </Form.Group>
          <Form.Group size="lg" controlId="password">
            <Form.Label>Password: </Form.Label>
              <Form.Control
                  autoFocus
                  type="password"
                  name="password"
                  value={password}
                  onChange={this.changeHandler}
              />
          </Form.Group>
          <div class='centered'>
            <button className = 'button' onClick={this.submitHandler}><span>Register</span></button>
          </div>
          <div className = 'logcenter'><p>Already have an account? <Link to='/login'>Login</Link></p></div>
        </Form>
     </div>
     </div>
    );
  }
}

export default Register;
