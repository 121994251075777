import React, {useEffect , useState} from 'react';
import '../../App.css';
import ExperimentsCards from './ExperimentsCards';
import axios from 'axios';
import Cookie from 'js-cookie'
import PageNotAuthorized from './PageNotAuthorized';
import { useIsAuthenticated } from '@azure/msal-react';
import { BackEndpath } from './APIpath';


const handlePrivate = async e => {
  const headers = {
      authorization: `Bearer ${Cookie.get("jwt")}`
  }
  const response = await axios.get(`${BackEndpath}/api/user/private` , { headers });

  if (response.data.message){
      return true;
  }else{
      return false;
  }
  };

export default function Experiments
() {
  const [access , setAccess] = useState(false)
  const isAuthenticated = useIsAuthenticated()

  useEffect(() => {
    handlePrivate()
    .then((token) => {console.log(token); setAccess(token)})
  })

  return(
  <>
  {access === true || isAuthenticated?
  <>
  <h1 className='experiments'>EXPERIMENTS</h1>
  <ExperimentsCards />
  </> :
  <><PageNotAuthorized />
  </>}
  </>
  )
}
