import React from 'react';
import '../../../../App.css';
import '../SectionA.css';
import SectionA2Cards from './SectionA2Cards';

export default function SectionA2() {
    return(
        <>
        <div class = 'cont'>
            <h1 className = 'section-a-title'>Experiment A2: potential divider circuit for determining value of a resistor</h1>
        </div>
        <SectionA2Cards/>
        </>
    )
}
