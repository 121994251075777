import React from 'react';
import '../../../../App.css'
import Footer from '../../../Footer'
import '../SectionB.css';
import SectionB2Cards from './SectionB2Cards.js'


export default function SectionB2(){
    return(
        <>
        <div class = 'cont'>
            <h1 className = 'section-b2-title'>Experiment B2: step voltage transient response of inductor</h1>
        </div>
        <SectionB2Cards/>
        <Footer />
        </>
    )
}
