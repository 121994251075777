import React from 'react';
import '../../../../App.css';
import '../SectionA.css';
import SectionA1Cards from './SectionA1Cards';
import Footer from '../../../Footer'

export default function SectionA1() {
    return(
        <>
        <div class = 'cont'>
            <h1 className = 'section-a-title'>Experiment A1: potential divider circuit using a potentiometer</h1>
        </div>
        <SectionA1Cards/>
        <Footer />
        </>
    )
}