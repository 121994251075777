import React from 'react';
import '../../../App.css';
import './SectionE.css';
import SectionECards from './SectionECards'
import Footer from '../../Footer'

export default function SectionE() {
    return (
      <>
      <div class = 'cont'>
        <h1 className = 'section-e-title'>Part E: Diode IV characteristic and application circuits.</h1>
      </div>
      <SectionECards/>
      <Footer />
      </>
    );
  }
