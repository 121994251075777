import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import "./mButton.css"

export const SignInButton = () => {
    const { instance } = useMsal();

    const handleLogin = () => {
        instance.loginPopup(loginRequest).catch(e => {
            console.log(e);
        });
    }
    return (
        <button type = 'button' className = "buttonBlue" onClick={() => handleLogin()}><i class="fa fa-windows" aria-hidden="true"></i> <span>Login Using Microsoft Account</span></button>
    )
}


