import React from 'react';
import '../../../App.css';
import './SectionC.css';
import SectionCCards from './SectionCCards'
import Footer from '../../Footer'

export default function SectionC() {
    return (
      <>
      <div class = 'cont'>
        <h1 className = 'section-c-title'>Part C: Measurement of periodic voltage source with resistor, capacitor and inductor circuit.</h1>
      </div>
      <SectionCCards/>
      <Footer />
      </>
    );
  }
