import React, { Component} from 'react';
import Container from '../../../Container.js';
import CardItem from '../../../CardItem.js';
import '../../../Container.css';
import Frame from 'react-frame-component';
import '../SectionA.css'
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import {BackEndpath} from './../../APIpath';
import Cookie from 'js-cookie';

class SectionA1Cards extends Component{

    constructor(props){
        super(props);
        this.state = {
            A1_1 : "",
            mail_id : `${Cookie.get("mail_id")}`,
        }
    };

    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value });
      };

    submitAns = async e=>{
        e.preventDefault();
        axios
        .post(`${BackEndpath}/api/answers/submit`, {
            mail_id : this.state.mail_id,
            Section: ['A1_1'],
            Answer : [this.state.A1_1],
        }, {
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then((response) => {
            alert(JSON.stringify(response.data.message));
            window.location.reload();
        })
    }

    render(){
    return (
        <div className = 'cards'>
            <div className = 'cards__container'>
                <div className = 'cards__wrapper'>
                    <ul className='cards__items'>
                        <Container
                        text='Connect up the potentiometer circuit shown in figure A1.1. With : Vs = 3.3 V ,  R1 + R2 = 10 k Ω and RL (input resistance of ADC) > 500 k Ω. Picture showing wired up VL monitored in pin ‘PA0’ and Vs monitored in pin ‘PA1’'
                        />
                    </ul>
                    <ul className='cards__items'>
                        <CardItem
                        class = 'cards__item__pic-wrap-1'
                        src='images/figureA1.1.png'
                        text='potentiometer circuit diagram'
                        label='Figure A1.1'
                        />
                        <CardItem
                        class = 'cards__item__pic-wrap-1'
                        src='images/figureA1.2.png'
                        text='potentiometer circuit photo '
                        label='Figure A1.2'
                        />
                    </ul>
                    <ul className='cards__items'>
                        <Container
                        text='Micropython codes for the 2 channel voltmeter'
                        />
                    </ul>
                    <div class='icontainer'>
                    <Frame className = 'responsive-iframe'initialContent="<!DOCTYPE html><html><head></head><body><script src='https://gist.github.com/Deonatan/33ab6858bb034e6bb98171ea6b6aa883.js'></script></body></html>" />
                    </div>
                    <br></br>
                    <ul className='cards__items'>
                        <Container
                        text='Install PuTTY and open a serial COM port connection to the STM32F4VE board to monitor the printout of voltmeters reading as shown in the figure below.'
                        />
                    </ul>
                    <ul className='cards__items'>
                        <CardItem
                        class = 'cards__item__pic-wrap-1'
                        src='images/figureA1.3.png'
                        text='Connections with PuTTY terminal'
                        label='Figure A1.3'
                        />
                        <CardItem
                        class = 'cards__item__pic-wrap-1'
                        src='images/figureA1.4.png'
                        text='Sample Putty Output'
                        label='Figure A1.4'
                        />
                    </ul>
                    <ul className='cards__items'>
                        <Container
                        text='Verify the voltage output of the potentiometer at v1 can be varied from 0 V to 3.29 V. '
                        />
                    </ul>
                    <ul className='cards__items'>
                            <div className='cards__item__link'>
                                <li className='cards__item'>
                                    <div className='cards__item__info'>
                                        <h5 className = 'cards__item__text__cont'>Next set the potentiometer to have VL = 1.5 V. Connect a load resistor RL with values varying from 100 Ω to 10 k Ω and measure the load voltage VL with the intention to extract the Thevenin’s equivalent circuit for the potentiometer set at Vth = 1.5 V.  Use the extracted Thevenin's equivalent circuit to calculate the load voltage VL for RL= 200 Ω and 1 k Ω Measure the load voltage VL and compare against the calculated. Discuss the result in the space provided below:</h5>
                                        <br></br>
                                        <Form>
                                        <Form.Group controlId="AnswerA2_1">
                                            <Form.Control
                                            autoFocus
                                            as = "textarea"
                                            rows = {6}
                                            name = "A1_1"
                                            value = {this.state.A1_1}
                                            onChange = {this.changeHandler}
                                            />
                                        </Form.Group>
                                        <div class='righted'>
                                            <button className = 'button' onClick={this.submitAns}><span>Submit</span></button>
                                        </div>
                                        </Form>
                                    </div>
                                </li>
                            </div>
                        </ul>
                </div>
            </div>
        </div>
    )
    };
}

export default SectionA1Cards
