import React from 'react';
import '../../App.css';
import { Button } from '../Button'
import './Home.css'

function Home () {
    return (
        <div className = 'hero-container'>
            <video src='/videos/video-background.mp4' autoPlay loop muted/>
            <h1>IM1002 Laboratories</h1>
            <p></p>
            <div className = 'hero-btns'>
                <Button className='btns' buttonStyle = 'btn--outline' buttonSize='btn--large'>
                    Get Started
                </Button>
            </div>
        </div>
    )
}

export default Home;
