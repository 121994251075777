import React from 'react';
import '../../../../App.css';
import '../SectionB3.css';
import SectionB3Cards from './SectionB3Cards';
import Footer from '../../../Footer'

export default function SectionB3() {
    return(
        <>
        <div class = 'cont'>
            <h1 className = 'section-a-title'>Experiment B3: Laplace Transformation</h1>
        </div>
        <SectionB3Cards/>
     
       
        </>
    )
}

